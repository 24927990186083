import React, { useEffect, useState } from "react";
import { ConfigProvider, Modal, message, Skeleton } from "antd";
import CustomIcon from "../../Assets/CustomIcon";
import { axiosOrder } from "../../axios-order";
import { getUserId } from "../../components/TVChartContainer";

const LoadSaveViewModal = ({
  isLoadSaveViewModalOpen,
  handleLoadSaveViewModelClose,
  onLayoutClicked,
}) => {
  const [userLayouts, setUserLayouts] = useState([]);
  const [filteredLayouts, setFilteredLayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isLoadSaveViewModalOpen) {
      fetchUserLayouts();
    }
  }, [isLoadSaveViewModalOpen]);

  useEffect(() => {
    const filtered = userLayouts.filter((layout) =>
      layout.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLayouts(filtered);
  }, [searchTerm, userLayouts]);

  const fetchUserLayouts = async () => {
    setLoading(true);
    axiosOrder
      .get(
        `/chart/saveChart/4.4/charts?client=systemx.tradingview.com&user=${getUserId()}`
      )
      .then((result) => {
        setUserLayouts(result.data.data);
        setFilteredLayouts(result.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch user layouts:", error);
        message.error("Failed to load saved views");
        setLoading(false);
      });
  };

  const onLayoutDeleted = (id, name) => {
    axiosOrder
      .delete(
        `/chart/saveChart/4.4/deleteCharts?client=systemx.tradingview.com&user=${getUserId()}&userMappingId=${id}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          await fetchUserLayouts();
          message.success(`Layout ${name} is deleted`);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch user layouts:", error);
        message.error("Failed to delete layout");
      });
  };

  const onLayoutClick = (id) => {
    axiosOrder
      .get(
        `/chart/saveChart/4.4/chartsByMapping?client=systemx.tradingview.com&user=${getUserId()}&userMappingId=${id}`
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data[0];
          onLayoutClicked(data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch user layouts:", error);
        message.error("Failed to load layout");
      })
      .finally(() => {
        handleLoadSaveViewModelClose();
      });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleColor: "#111A21",
            titleFontSize: 20,
          },
        },
      }}
    >
      <Modal
        title="Your Saved Views"
        open={isLoadSaveViewModalOpen}
        onCancel={handleLoadSaveViewModelClose}
        footer={null}
      >
        <div className="flex flex-col gap-8 pt-8">
          <input
            type="text"
            className="text-sm font-normal leading-tight border border-zinc-200 py-2 px-3 rounded-md"
            placeholder="Search your views"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex flex-col gap-4 h-[200px] overflow-scroll">
            {loading ? (
              <div className="flex flex-col gap-2">
                <Skeleton />
              </div>
            ) : filteredLayouts.length === 0 ? (
              <p>No saved views found</p>
            ) : (
              filteredLayouts.map((layout, index) => (
                <div key={index} className="flex flex-row justify-between pr-4">
                  <button
                    className="flex-1 cursor-pointer flex justify-start"
                    onClick={() => onLayoutClick(layout.id)}
                  >
                    {layout.name || "Unnamed View"}
                  </button>
                  <button
                    className="p-1 rounded-md bg-white hover:bg-[#0000000F] transition-colors"
                    onClick={() => onLayoutDeleted(layout.id, layout.name)}
                  >
                    <CustomIcon
                      iconName={"deleteIcon"}
                      className="cursor-pointer fill-gray-500 hover:fill-black"
                    />
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default LoadSaveViewModal;
