import React, { useEffect, useState, useCallback } from "react";
import "./MultiCharts.Style.css";
import NotFound from "../components/NotFound";
import { getUserId } from "../components/TVChartContainer";
import MultiChartContainer from "./MultiChartContainer";
import { message } from "antd";
import { axiosOrder } from "../axios-order";
import CustomIcon from "../Assets/CustomIcon";
import { connect } from "react-redux";
import { saveUserTheme } from "../store/actionsType";
import SaveViewModal from "./modal/SaveViewModal";
import LoadSaveViewModal from "./modal/LoadSaveViewModal";
import { compress, decompress } from "compress-json";

const MultiCharts = (props) => {
  const [chartWidgets, setChartWidgets] = useState([]);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [chartViewContent, setChartViewContent] = useState([]);
  const [isLoadSaveViewModalOpen, setIsLoadSaveViewModalOpen] = useState(false);
  const [activeLayoutId, setActiveLayoutId] = useState();
  const [viewName, setViewName] = useState("");

  const hanldleSaveOk = () => {
    storeChartLayouts(chartViewContent);

    setIsSaveModalOpen(false);
    setViewName("");
  };

  const handleSaveModelClose = () => {
    setIsSaveModalOpen(false);
  };

  const handleLoadSaveViewModalClose = () => {
    setIsLoadSaveViewModalOpen(false);
  };

  useEffect(() => {
    axiosOrder
      .get("/navbar/getUserThemeById?userId=" + getUserId())
      .then((result) => {
        result = result.data;
        props.onThemeChanged(result);
        localStorage.setItem("tvTheme", result);
      })
      .catch((exception) => {
        console.log(exception);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("source", "systemxLite");
  }, []);

  const handleWidgetReady = useCallback((widget) => {
    setChartWidgets((prev) => [...prev, widget]);
  }, []);

  const storeChartLayouts = async (layout) => {
    try {
      const response = await axiosOrder.post(
        `/chart/saveChart/4.4/saveMultiD?client=systemx.tradingview.com&user=${getUserId()}`,
        {
          chartName: viewName,
          symbol: "multichart",
          resolution: "multichart",
          content: JSON.stringify(layout),
        }
      );

      if (response.status === 200) {
        message.success("Layouts are saved");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (axiosError) {
      console.error("Failed to save chart layout on server:", axiosError);
      message.error("Failed to save layouts on server");
    }
  };

  const onLayoutClicked = (data) => {
    const parsedData = JSON.parse(data.content);

    if (parsedData && chartWidgets.length > 0) {
      if (parsedData.length <= chartWidgets.length) {
        parsedData.forEach((layout, index) => {
          chartWidgets[index].load(layout);
        });
      }
    }
  };

  const saveAllCharts = () => {
    if (chartWidgets.length === 0) return;
    const chartLayouts = [];

    chartWidgets.forEach((widget, index) => {
      widget.save((layout) => {
        chartLayouts.push(layout);

        if (chartLayouts.length === chartWidgets.length) {
          setChartViewContent(chartLayouts);
          setIsSaveModalOpen(true);
        }
      });
    });
  };

  const darkModeToggle = () => {
    const newTheme = props.theme === "Dark" ? "Light" : "Dark";
    localStorage.setItem("tvTheme", newTheme);
    props.onThemeChanged(newTheme);
  };

  const getAuth = () => {
    if (
      localStorage.getItem("persist:systemx-lt-") !== undefined &&
      localStorage.getItem("persist:systemx-lt-") !== null
    ) {
      return JSON.parse(
        JSON.parse(localStorage.getItem("persist:systemx-lt-")).auth
      ).userId;
    } else {
      return null;
    }
  };

  const updateView = () => {
    const layout = compress(chartViewContent);
    const response = axiosOrder.put(
      `/chart/saveChart/4.4/chartsByMapping?client=systemx.tradingview.com&user=${getUserId()}&userMappingId=${activeLayoutId}`,
      [
        {
          id: activeLayoutId,
          timestamp: "multichart",
          symbol: "multichart",
          resolution: "multichart",
          content: JSON.stringify(layout),
        },
      ]
    );

    if (response.status === 200) {
      message.success("Layouts are updated");
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  };

  if (getAuth() === null) {
    return <NotFound />;
  }

  return (
    <div
      className={`${
        props.theme === "Dark" ? "bg-gray-900" : "bg-white"
      } h-[100vh]`}
    >
      {/* <div className="flex justify-between gap-3 pl-2 pt-2 pb-1 pr-4">
        <button
          onClick={saveAllCharts}
          className={`${
            props.theme === "Dark"
              ? "text-zinc-100 bg-white bg-opacity-5 border-zinc-200 border-opacity-5"
              : "text-gray-900 border-zinc-200"
          } text-sm font-medium leading-tight border rounded-md pl-2.5 pr-4 py-2 pb-1 shadow flex gap-2`}
        >
          <CustomIcon
            iconName={"tickIcon"}
            className={props.theme === "Dark" ? "fill-[#f4f4f5]" : "fill-black"}
          />
          Save New View
        </button>
        <div className="flex flex-row gap-3">
          <button
            onClick={() => setIsLoadSaveViewModalOpen(true)}
            className={`${
              props.theme === "Dark"
                ? "text-zinc-100 border-[#D5FF15] bg-opacity-5"
                : "text-gray-900 border-blue-600"
            } text-sm font-bold italic leading-tight rounded-md pl-2.5 pr-4 py-2 border flex gap-2`}
          >
            Your Saved Views
            <CustomIcon
              iconName={
                props.theme === "Dark" ? "downArrowDark" : "downArrowLight"
              }
            />
          </button>
          <button
            onClick={updateView}
            className={`${
              props.theme === "Dark"
                ? "text-zinc-100 bg-white bg-opacity-5 border-zinc-200 border-opacity-5"
                : "text-gray-900 border-zinc-200"
            } text-sm font-medium leading-tight rounded-md pl-2.5 pr-4 py-2 pb-1 shadow border flex gap-2`}
          >
            <CustomIcon
              iconName={"circleArrow"}
              className={
                props.theme === "Dark" ? "fill-[#f4f4f5]" : "fill-black"
              }
            />
            Update this view
          </button>
          <button className="p-5.5" onClick={darkModeToggle}>
            <CustomIcon
              iconName={props.theme === "Dark" ? "sunIcon" : "moonIcon"}
            />
          </button>
        </div>
      </div> */}

      <div className="grid grid-cols-2 grid-rows-2 h-full flex-1">
        {[1, 2, 3, 4].map((chartNum) => (
          <MultiChartContainer
            key={chartNum}
            containerId={`MultiChartContainer_${chartNum}`}
            onWidgetReady={handleWidgetReady}
          />
        ))}
      </div>
      <SaveViewModal
        isSaveModalOpen={isSaveModalOpen}
        handleSaveModelClose={handleSaveModelClose}
        hanldleSaveOk={hanldleSaveOk}
        setViewName={setViewName}
      />

      <LoadSaveViewModal
        isLoadSaveViewModalOpen={isLoadSaveViewModalOpen}
        handleLoadSaveViewModelClose={handleLoadSaveViewModalClose}
        onLayoutClicked={onLayoutClicked}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onThemeChanged: (theme) => dispatch(saveUserTheme(theme)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiCharts);
